






























































































































































































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RewardViewModel } from '../../viewmodel/reward-viewmodel'
import { debounce } from 'lodash'
@Observer
@Component
export default class extends Vue {
  @Inject({}) vm!: RewardViewModel
}
