import { render, staticRenderFns } from "./reward-post.vue?vue&type=template&id=58706a15&scoped=true&"
import script from "./reward-post.vue?vue&type=script&lang=ts&"
export * from "./reward-post.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58706a15",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VImg,VPagination,VSheet,VTooltip})
